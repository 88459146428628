export const shopNowCard = [
  {
    id: 1,
    url: '../assets/images/card-top-left.webp',
    capitalHeading: 'Medical Contact Lenses',
    buttonElement: true,
    background: '#6E6E65',
    buttonText: 'Shop Now',
    link : 'https://lensbooking.com/contact-lenses-online/',
    samePage : false
  },
  {
    id: 2,
    url: '../assets/images/card-top-right.webp',
    capitalHeading: 'Colored Contact Lenses',
    buttonElement: true,
    background: '#777769',
    buttonText: 'Shop Now',
    link : 'https://lensbooking.com/colored-contact-lenses/',
    samePage : false

  },
  {
    id: 3,
    url: '../assets/images/card-bottom-left.webp',
    capitalHeading: 'Eyewear',
    buttonElement: true,
    background: '#2A2F38',
    buttonText: 'Shop Now',
    link : 'https://lensbooking.com/colored-contact-lenses/',
    samePage : true

  },
  {
    id: 4,
    url: '../assets/images/card-bottom-right.webp',
    capitalHeading: 'Accessories',
    buttonElement: true,
    background: '#474D50',
    buttonText: 'Shop Now',
    link : 'https://lensbooking.com/colored-contact-lenses/',
    samePage : false
  },
];

export const brandsCard = [
  {
    id: 1,
    url: '../assets/images/Inspire-Me.jpg',
 
    capitalHeading: 'LINDBERG',
    buttonElement: true,
    background: '#6E6E65',
    buttonText: 'Shop Now',
  },
  {
    id: 2,
    url: '../assets/images/Inspire-Me-2.jpg',
  
    capitalHeading: 'MYKITA',
    buttonElement: true,
    background: '#777769',
    buttonText: 'Shop Now',
  },
  {
    id: 3,
    url: '../assets/images/Inspire-Me-1.jpg',
    capitalHeading: 'LAFONT',
    buttonElement: true,
    background: '#2A2F38',
    buttonText: 'Shop Now',
  },
  // {
  //   id: 4,
  //   url: '../assets/images/brand-rayban.webp',
  //   capitalHeading: 'RAYBAN',
  //   buttonElement: true,
  //   background: '#474D50',
  //   buttonText: 'Shop Now',
  // },
  // {
  //   id: 5,
  //   url: '../assets/images/brand-cartier.webp',
  //   capitalHeading: 'CARTIER',
  //   buttonElement: true,
  //   background: '#474D50',
  //   buttonText: 'Shop Now',
  // },
];

export const customerReviewsCard = [
  {
    id: 1,
    url: "../../../assets/images/testimonials-image1.png",
    description: 'Do you want to see better? Visit VISIQUE',
  },
  {
    id: 2,
    url: '../../../assets/images/testimonials-image2.png',
    description: 'Great collection! Excellent',
  },
  {
    id: 3,
    url: '../../../assets/images/testimonials-image3.png',
    description: 'I celebrated my birthday with a new glasses from your Naccache hranch!!',
  },
];

export const blogCard = [
  {
    id: 1,
    url: '../assets/images/blog1.webp',
    capitalHeading: 'Kids and Glasses',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    id: 2,
    url: '../assets/images/blog2.webp',
    capitalHeading: 'Service with a smile',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. At quis amet mauris malesuada cursus.',
  },
  {
    id: 3,
    url: '../assets/images/blog3.webp',
    capitalHeading: 'How to choose the perfect glasses',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. At quis amet mauris malesuada cursus.',
  },
];
