<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Low Vision Aids</h1>
      <p>
        VISIQUE Optometrists is equipped with the latest in vision technology.
        Eschenbach, a leading global supplier of optical products, provides us a wide
        range of magnifying vision aids at our Low Vision Centre, ensuring that our
        patients receive the optimal solutions to meet their daily vision needs.
      </p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading text-center">
      <span>Low Vision Aids</span>
    </h1> -->
    <div class="card-container">
      <!-- <div class="row mb-3">
        <div class="col-md-6">
         
          <h5 class="image-meta">Low Vision Aids</h5>
        </div>
        <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">
              Book An Appointment
            </button>
          </div>
        </div>
      </div> -->
      <div class="card-description">
        <p>
          Low Vision refers to a persistent loss of functional vision that remains even after
          correcting distance refraction. While eye diseases are the primary cause of Low
          Vision, it can also result from inherited conditions or injuries to the eye or brain.
        </p>

        <h1>Do You Feel the Need to Check a Low Vision Therapist?</h1>
        <p>
          Are you struggling with reading, writing, watching TV, recognizing objects from a
          distance, or enjoying your hobbies? With the right optical device and proper
          training, many Low Vision patients can regain their ability to perform daily
          activities and restore much of their lost independence. A Certified Low Vision
          Therapist uses specialized vision instruments to assess visual acuity, fields of
          vision, perceptual and motor functioning, color vision, contrast sensitivity, and
          stereopsis, as well as skills related to reading and writing, to evaluate visual
          disabilities and impairments.
        </p>

        <h3>What to expect in the 1-hour low vision Exam?</h3>
        <ul>
          <li>
            A thorough comprehensive history of the visually impaired individual is taken.
          </li>
          <li>
            The cause and effect of the impairment are identified to perform a
            complete subjective refraction, aiming to achieve the best possible retinal
            image.
          </li>
          <li>
            Visual acuity is measured with the optimal lens prescription to determine
            the necessary magnification.
          </li>
          <li>
            The patient&#39;s ability to understand and use the most effective visual aids for
            required tasks is assessed.
          </li>
          <li>
            Finally, the selection of Low Vision Aids is made collaboratively by the
            practitioner and the visually impaired person.
          </li>
        </ul>
      </div>
      <img class="card-image"
      src="../../../assets/images/Low Vision.jpg"
      alt=""
    />
    </div>
    <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title> What causes low vision? </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Many medical conditions can lead to low vision. In younger individuals, causes
                may include glaucoma, cortical blindness, albinism, or optic atrophy. For adults,
                low vision can be associated with diabetes, optic atrophy, cataracts, macular
                degeneration, and corneal diseases like keratoconus.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Is there a certain age group of higher risk for low vision??
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Low vision can affect individuals of any age. It may be congenital (born with),
                occurring in those under 18, or it can develop later in life, particularly in
                individuals over the age of 65.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Are there any glasses or eyewear that help with low vision?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Low vision eyeglasses can assist patients with macular degeneration by providing
                a larger print and a clearer view. These eyeglasses often feature anti-reflective
                coatings. Additionally, low vision aids designed for reading can help maximize
                word size, further improving readability.
              </mat-panel-description>
            </mat-expansion-panel>

            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Are there any options that may help with low vision?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Seeing a low vision eye doctor and undergoing a low vision exam are essential for
                determining the most effective solutions for the patient. These evaluations help
                tailor low vision technology to the patient&#39;s needs, and the specialist can provide
                recommendations on the best options and resources available.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What are the best low vision aids that can be helpful for a patient’s daily lifestyle?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                You can visit our low vision center to explore products that make daily life easier
                for individuals with low vision. We offer tools such as clocks, scales, calculators,
                and telephones with larger, clearer numbers for enhanced comfort. Additionally,
                our center provides low vision reading aids that magnify text to facilitate easier
                reading.
              </mat-panel-description>
            </mat-expansion-panel>
            <!-- <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What are the best low vision aids that can be helpful for
                  daily lifestyle for patients?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                You can visit a low vision shop to see the products that
                simplify everyday lifestyle for low vision patients. A low
                vision store can sell tools, such as clocks, scales, calculators
                and telephones with bigger and clearer numbers for the comfort
                of visually impaired patients. There are also low vision reading
                aids that help magnify the words for the patient's ease.
              </mat-panel-description>
            </mat-expansion-panel> -->
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
