<div class="who-are-we-wrapper">
  <div class="container-fluid">
    <h1 class="section-heading mt-3">
      <span>Who Are We?</span>
    </h1>
    <div class="header-wrapper">
      <div class="image-wrapper mb-3">
        <img src="../../../assets/images/New-Who-We-are.jpg" alt="" />
      </div>
      <div class="description mb-5">
        <h1>The Emergence of Our Eye care Clinic</h1>
        <p>
          Optical retailing is rapidly expanding in the Middle East, driven by increased
          patient awareness and evolving lifestyles.
        </p>
        <p>
          VISIQUE Optometrists was established in 2007 and is dedicated to offering
          superior quality retailing, emphasizing advanced technical knowledge in
          diagnostics and ultimate patient satisfaction. This commitment is realized through
          the dedication and expertise of our well-trained professionals.
          Under the leadership of our founder, Liliane Tanal (O.D., M.Sc.) who brings over
          25 years of experience, VISIQUE Optometrists Boutiques are meticulously
          equipped to embody the company’s core mission and values.
        </p>
        <p>
          We currently operate seven branches across two countries, with locations in
          Lebanon including Naccahe, Achrafieh, Hamra, and Bayada; and in the UAE at
          Dubai Hills Mall, Yas Mall Abu Dhabi, and City Centre Al Zahia Sharjah. As part of
          our ongoing expansion and growth plan, we are eagerly anticipating the opening
          of a new branch in Riyadh, Saudi Arabia, marking our entry into a promising new
          market and further strengthening our presence in the region.
        </p>
        <p>
          Our objective is to establish long-term relationships with our customers by
          providing professional and personalized service. This commitment is reflected in
          our team&#39;s dedication to meeting your needs and exceeding your expectations.
          In a market with abundant retail choices, VISIQUE Optometrists boutiques stand
          out by offering a serene environment within an award-winning architectural
          design, featuring an optometry clinic, a professional lab, and a wide selection of
          luxury designer frames and high-end ophthalmic lenses.
          
          
        </p>
        <p>
          At VISIQUE Optometrists, we serve with integrity and deliver distinction!
        </p>
      </div>
      <div class="row">
        <div class="col-md-4 text-center mb-3">
          <div class="inner-box">
            <h5>FOUNDED IN</h5>
            <h4>2007</h4>
          </div>
        </div>
        <div class="col-md-4 text-center mb-3">
          <div class="inner-box">
            <h5>TALENTED STAFF</h5>
            <h4>20+</h4>
          </div>
        </div>
        <div class="col-md-4 text-center mb-3">
          <div class="inner-box">
            <h5>HAPPY CLIENTS</h5>
            <h4>200+</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="middle-wrapper">
      <h2 class="section-heading">
        <span>Our Mission</span>
      </h2>
      <div class="card-wrapper">
        <div class="card-description">
          <p>
            Our mission is to build lasting relationships with our patients by offering high-
            quality products and exceptional service within a caring and professional
            environment. This commitment is evident in every VISIQUE boutique and within
            each member of our staff.
          </p>
          <p>
            We strive to redefine the vision care industry by going beyond conventional
            standards to exceed our patients&#39; expectations. We invite you to explore our
            carefully curated collection online and in-store, where you&#39;ll discover a blend of
            exquisite quality and competitive pricing.
          </p>
          <p>
            At VISIQUE, we foster an inviting and friendly environment, where our team
            balances professionalism with a personal touch.
            Your comfort and satisfaction are the essence of our service, which is why we
            always ensure that you leave our boutiques feeling valued and delighted!
          </p>
          <h3>Visit our Eye Care Center</h3>
          <p>
            Our boutiques are thoughtfully designed to embody the exceptional service and
            quality we offer to our esteemed customers. As soon as they enter, customers will
            immediately notice our uniqueness starting with the reception staff, who are
            well-prepared to assist with any inquiries, to our highly skilled and certified
            optometrists and lab technicians.
          </p>
          <h3>
            Experience Refined Eye Care Expertise 
            <a href="https://www.visiqueme.com/">at VISIQUE Optometrists Boutiques!</a>
          </h3>
          <p>
            With so many retail options available today, VISIQUE Optometrists offers a
            distinctive experience in a serene environment within an award-winning
            architectural design. Our facility includes a top-tier optometry clinic, a
            professional lab, and a wide range of luxury designer frames and premium

            <a href="" routerLink="/ophthalmic-lenses">ophthalmic lenses.</a>
          </p>
          <p>
            Our dedicated team provides comprehensive vision and eye care in a warm,
            professional atmosphere. We are committed to upholding the highest ethical
            standards, using advanced technology, and fostering long-term relationships with
            our patients and their families.
          </p>
          <p>
            VISIQUE holds a Dubai Health Authority (DHA) license under the Regulation for
            Optical Center and Optometry Services, authorizing our optometrists to legally
            perform eye examinations at our eye test center.
          </p>
          <p>
            Stay connected with us on social media and subscribe to our newsletters to keep
            up with our latest promotions and offers!
          </p>
          <div>
          </div>
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">Book your appointment today</button>
          </div>
        </div>
      </div>
    </div>
    
    <div class="end-wrapper">
      <h1 class="section-heading">
        <span>Our Head Team</span>
      </h1>
      <div class="end-image-wrapper">
        <div class="row row-head">
          <div class="col-md-6">
            <div class="meta-box">
              <a routerLink="/meet-liliane">
                <img
                  class="img-fluid"
                  src="../../../assets/images/who-are-we-liliane.png"
                  alt="Liliane Tanal Barake"
                />
                <h5>Liliane Tanal Barake O.D., M.SC</h5>
                <h6>Founder & CEO</h6>
              </a>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="meta-box">
              <img src="../../../assets/images/who-are-we-juliana.png" alt="" />
              <h5>Juliana Van Niekerk</h5>
              <h6>Project manager</h6>
            </div>
          </div> -->
          <!-- <div class="col-md-8 book-container">
              <div class="book-wrapper">
                  <button>Book An Appointment</button>
              </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
