<div class="contact-us-wrapper">
  <div class="container-fluid">
    <h1 class="section-heading">
      <span>Contact Us</span>
    </h1>
    <div class="card-wrapper">
      <div class="row mb-4">
        <div class="col-md-4 mb-2">
          <div class="contact-box">
            <img src="../../../assets/images/phone.svg" alt="logo" />
            <h5>CONTACT PHONE NUMBER</h5>
            <h6>+971 504891873</h6>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="contact-box">
            <img src="../../../assets/images/mail.svg" alt="logo" />
            <h5>EMAIL ADDRESS</h5>
            <h6>customerservice@visiqueme.com</h6>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="contact-box">
            <img src="../../../assets/images/map.svg" alt="logo" />
            <h5>OUR LOCATIONS</h5>
            <h6 class="location-details">
              Ground Floor, Unit GF378,<br />
              Dubai Hills Mall,<br />
              Dubai Hills Estate,Dubai<br />
            </h6>
          
            <h6 class="location-details">
              Ground Floor, Unit G024,   <br />
              City Center Al Zahia,<br />
              Sharjah<br />
            </h6>
            <h6 class="location-details">
              Lower Ground, Next to Carrefour,   <br />
              Unit LG005,Yas Mall, Yas Island,<br />
              Abu Dhabi<br />
            </h6>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-md-4 mb-2">
          <div class="contact-box">
            <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.010104734117!2d55.237802315007656!3d25.101519283940746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ff8374fbea3%3A0xac6272dc74541f88!2sVisique%20Optometrists!5e0!3m2!1sen!2sin!4v1652779887572!5m2!1sen!2sin"
            height="450" style="width: 100%; border: none" allowfullscreen="" class="embed-responsive-item" allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="contact-box">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.6106909933114!2d55.45287837402952!3d25.317280826828934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5f00019939ff%3A0x338381db72da0167!2sVISIQUE%20Optometrists%20City%20Centre%20Al%20Zahia!5e0!3m2!1sen!2sin!4v1729161041825!5m2!1sen!2sin" 
            height="450" style="width: 100%; border: none" allowfullscreen="" class="embed-responsive-item" allowfullscreen></iframe>
          </div>
        </div>
        <div class="col-md-4 mb-2">
          <div class="contact-box">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3630.8931305071014!2d54.60706077399755!3d24.48915946001714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5e4f645aedc73d%3A0x20995ed010e4a487!2sVisique%20Optometrists%20Yas%20Mall!5e0!3m2!1sen!2sin!4v1729161124832!5m2!1sen!2sin" 
            height="450" style="width: 100%; border: none" allowfullscreen="" class="embed-responsive-item" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <!-- <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.010104734117!2d55.237802315007656!3d25.101519283940746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ff8374fbea3%3A0xac6272dc74541f88!2sVisique%20Optometrists!5e0!3m2!1sen!2sin!4v1652779887572!5m2!1sen!2sin"
        height="450" style="width: 100%; border: none" allowfullscreen="" class="embed-responsive-item" allowfullscreen
      ></iframe> -->
      <h5 class="form-heading mt-5">Have Question? Write a Message</h5>
      <div class="form-wrapper">
        <form [formGroup]="contactForm">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstName" />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    contactForm.controls['firstName'].invalid &&
                    (contactForm.controls['firstName'].dirty ||
                      contactForm.controls['firstName'].touched)
                  "
                >
                  <div
                    *ngIf="contactForm.controls['firstName'].errors?.['required']"
                    class="error-txt"
                  >
                    First Name is Required
                  </div>
                  <div
                    *ngIf="contactForm.controls['firstName'].errors?.['pattern']"
                    class="error-txt"
                  >
                    Enter valid name
                  </div>
                  <div
                    *ngIf="contactForm.controls['firstName'].errors?.['maxlength']"
                    class="error-txt"
                  >
                    Maximum 50 characters are allowed.
                  </div>
                </mat-error>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName" />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    contactForm.controls['lastName'].invalid &&
                    (contactForm.controls['lastName'].dirty ||
                      contactForm.controls['lastName'].touched)
                  "
                >
                  <div
                    *ngIf="contactForm.controls['lastName'].errors?.['required']"
                    class="error-txt"
                  >
                    Last Name is Required
                  </div>
                  <div
                    *ngIf="contactForm.controls['lastName'].errors?.['pattern']"
                    class="error-txt"
                  >
                    Enter valid name
                  </div>
                  <div
                    *ngIf="contactForm.controls['lastName'].errors?.['maxlength']"
                    class="error-txt"
                  >
                    Maximum 50 characters are allowed.
                  </div>
                </mat-error>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Your Phone Number</mat-label>
                  <input matInput formControlName="phoneNo" />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    contactForm.controls['phoneNo'].invalid &&
                    (contactForm.controls['phoneNo'].dirty ||
                      contactForm.controls['phoneNo'].touched)
                  "
                >
                  <div
                    *ngIf="contactForm.controls['phoneNo'].errors?.['required']"
                    class="error-txt"
                  >
                    Phone is Required
                  </div>
                  <div
                    *ngIf="contactForm.controls['phoneNo'].errors?.['pattern']"
                    class="error-txt"
                  >
                    Enter valid Phone Number
                  </div>
                </mat-error>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Your Email</mat-label>
                  <input matInput formControlName="emailId" />
                </mat-form-field>
                <mat-error
                  *ngIf="
                    contactForm.controls['emailId'].invalid &&
                    (contactForm.controls['emailId'].dirty ||
                      contactForm.controls['emailId'].touched)
                  "
                >
                  <div
                    *ngIf="contactForm.controls['emailId'].errors?.['required']"
                    class="error-txt"
                  >
                    Email is Required
                  </div>
                  <div
                    *ngIf="contactForm.controls['emailId'].errors?.['pattern']"
                    class="error-txt"
                  >
                    Enter valid Email
                  </div>
                </mat-error>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <mat-form-field class="example-full-width" appearance="outline">
                  <mat-label>Your Message</mat-label>
                  <input matInput formControlName="message" />
                  <mat-error
                  *ngIf="
                    contactForm.controls['message'].invalid &&
                    (contactForm.controls['message'].dirty ||
                      contactForm.controls['message'].touched)
                  "
                >
                  <div
                    *ngIf="contactForm.controls['message'].errors?.['required']"
                    class="error-txt mt-3"
                  >
                    Message is Required
                  </div>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="submit-btn text-center mt-4">
              <button type="button" (click)="onContactFormSubmit()">
                Submit Now
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
