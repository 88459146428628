<home-slider></home-slider>
<!-- <div class="book-Appointment-wrapper">
  <div class="container mt-3 mb-3">
    <div class="row">
      <div class="col-md-9 mb-3">
        <h1>Book An Appointment Today</h1>
      </div>
      <div class="col-md-3 text-center mb-3">
        <img src="../../../assets/images/eye-glass.svg" alt="eye-glass-icon" />
      </div>
    </div>
    <div class="form-wrapper">
      <form [formGroup]="sheduleForm" (ngSubmit)="onSheduleBooking()">
        <div class="row">
          <div class="col-md-9">
            <div class="form-group">
              <input
                formControlName="email"
                class="w-100"
                type="text"
                name="search"
                id="user-email"
                placeholder="Enter your Email Address.."
              />
              <div
                *ngIf="
                  sheduleForm.controls['email'].invalid &&
                  (sheduleForm.controls['email'].dirty ||
                    sheduleForm.controls['email'].touched)
                "
              >
                <div
                  *ngIf="sheduleForm.controls['email'].errors?.['required']"
                  class="error-txt"
                >
                  Email is Required
                </div>
                <div
                  *ngIf="sheduleForm.controls['email'].errors?.['pattern']"
                  class="error-txt"
                >
                  Enter valid Email
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3 text-center">
            <button type="submit" class="schedule-button">SCHEDULE</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div> -->
<shop-now-card [content]="shopNowCard"></shop-now-card>
<div class="crawler-section1">
  <div class="text">
    <h1>VISIQUE Optometrists are at your service!</h1>
  </div>
</div>
<our-brands [content]="brandsCard"></our-brands>
<div class="crawler-section2">
  <div class="text">
    <h1>
      We are specialized in a wide range of vision & eye care services including
      Myopia & Presbyopia management, Pediatric care, Specialized Contact Lenses
      for Keratoconus and Post-Surgical Corneas & Low Vision Aids.
    </h1>
  </div>
</div>
<div class="container">
  <div class="card-container">
    <div class="card-wrapper">
      <div class="card-content">
        <h1 class="heading-center text-center">Our Store</h1>
        <div class="more-button text-center">
          <button routerLink="/contact-us">Contact Us</button>
        </div>
      </div>
    </div>
  </div>
</div>
<customer-card [content]="customerCard"></customer-card>
<app-seo-page></app-seo-page>
<!-- <blog [content]="blogCard"></blog> -->
<app-instagram-feed>
  
</app-instagram-feed>
<div class="container-fluid mt-5 mb-5">
  <div class="faq-wrapper">
    <div class="logo-header">
      <img src="../../../assets/images/faq-frame-image.png" alt="" />
      <h2>Frequently Asked Questions</h2>
    </div>
    <div class="specialities-faq">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Do I need to create an account for VISIQUE ME in order to shop? </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            VISIQUE opticals online Dubai shop is linked to Lensbooking, which is an online optical shop 
            selling various kinds of contact lenses and reading glasses. You can register to create an 
            account there, or log in using your account. You can also order as a guest and all needed 
            information will be taken at checkout. 
          </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Do I need an official prescription to order optics or contact lenses on VISIQUE?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            If you already know the power of your lenses, then you can order directly from the website  
            without any prescription.
            If not, you can upload your official prescription on the website and we will help you with the     
            powers.
          </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Can we purchase optical frames and ophthalmic lenses on the VISIQUE website?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            While you cannot purchase optical frames and ophthalmic lenses directly on
            the VISIQUE website, you can book an appointment with our team of
            professionals at our optical centers in Dubai, Abu Dhabi, and Sharjah. They will
            help you select the perfect high-end optical frames and glasses that best suit
            your personal style!
          </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Do you offer special promotions for contact lenses and optical frames?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            Subscribe to our newsletter to stay up-to-date with the latest news and offers on
            contact lenses and optical frames or visit our shops in Dubai Hills Mall, Yas Mall
            Abu Dhabi, and City Center Al Zahia Sharjah for more information.
          </mat-panel-description>
        </mat-expansion-panel>
        <!-- <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Do you release any contact lenses and optical offers in Dubai?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            Check out our monthly optical offers in Dubai, which you can stay up to date with by 
            subscribing to our newsletter.
          </mat-panel-description>
        </mat-expansion-panel> -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              What do I do if my contact lens is stuck under my eyelid?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            Getting your contact lens stuck is common and not dangerous. 
            To remove the contact lens, you must ensure your hands are clean, 
            so washing and drying your hand is important. Continuously massaging 
            the upper eyelid can help loosen the contact lens and therefore release 
            it from under the eyelid. Always check if your eyes are irritated and need 
            professional medical attention. 
          </mat-panel-description>
        </mat-expansion-panel>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              What happens if I fall asleep with my contact lenses in my eyes?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-panel-description>
            It is not advisable to sleep with your contact lenses. But if it happened, it is most common 
            that the contact lenses have dried inside the eye. What you can do is re-hydrate the eye 
            using eye drops or eye solution and then take out the contact lenses immediately. Do not put 
            your contact lenses in for some time until your eyes are fully hydrated again, this could take a day or more. 
          </mat-panel-description>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
<!-- <ngx-insta [config]="config"></ngx-insta>  -->
