<div class="container-fluid">
  <div class="testimonials-container">
    <h1 class="section-heading text-center">
      <span>Testimonials</span>
    </h1>
      
        <div class="cards col-md-12 flex-row d-flex justify-content-between">
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image1.png"
                alt="testimonials-image1"
              /> -->
              <h4>Anita Suri</h4>
              <!-- <h5>Owner - Mzannar Jewerly</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p>“Excellent service and product range…”</p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image2.png"
                alt="testimonials-image2"
              /> -->
              <h4>Ernest Coetzee</h4>
              <!-- <h5>Ambassador of Mexico to Lebano</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p>“Very knowledgeable staff and excellent service!”</p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image2.png"
                alt="testimonials-image2"
              /> -->
              <h4>Lena Khalil</h4>
              <!-- <h5>Ambassador of Mexico to Lebano</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p>“By far the best I’ve experienced in Dubai!”</p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
        </div>
     
        <div class="cards col-md-12 flex-row d-flex justify-content-between">
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image3.png"
                alt="testimonials-image1"
              /> -->
              <h4>Partha S Chaliha</h4>
              <!-- <h5>Owner - Mzannar Jewerly</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“Wonderful experience visiting your place. Optometrists are well experienced 
                  and have a depth of knowledge in the field.”</p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image4.png"
                alt="testimonials-image2"
              /> -->
              <h4>A K </h4>
              <!-- <h5>Ambassador of Mexico to Lebano</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“Friendly, well informed and really took their time with us.
                  Frame selection is one of the most well thought out collections in Dubai.”
                  </p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image4.png"
                alt="testimonials-image2"
              /> -->
              <h4>Fawzi Aldibyat </h4>
              <!-- <h5>Ambassador of Mexico to Lebano</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“Best boutique ever to get eye glasses and contact lenses, professional and friendly 
                  staff. Highly recommended.”</p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
        </div>

        <div class="cards col-md-12 flex-row d-flex justify-content-between">
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image3.png"
                alt="testimonials-image1"
              /> -->
              <h4>Kamal Shehadi </h4>
              <!-- <h5>Owner - Mzannar Jewerly</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“The most professional optometrist in Dubai and the best advice. They’re not about 
                  selling you frames and lenses; they’re about serving the patient.”</p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image4.png"
                alt="testimonials-image2"
              /> -->
              <h4>Roby 86</h4>
              <!-- <h5>Ambassador of Mexico to Lebano</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“Amazing and professional staff, in pursue for finding the best eyeglass for your 
                  needs and look. Great experience and can definitely recommend. 👍🏻”
                </p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image4.png"
                alt="testimonials-image2"
              /> -->
              <h4>Majed Alafridi </h4>
              <!-- <h5>Ambassador of Mexico to Lebano</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“Nice and unique collection competitively priced. I had a very good experience so 
                  far. I bought 3 sunglasses for my family and I fixed my glasses and all were satisfied.”</p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
        </div>

        <div class="cards col-md-12 flex-row d-flex justify-content-between">
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image3.png"
                alt="testimonials-image1"
              /> -->
              <h4>Anel Van Deventer </h4>
              <!-- <h5>Owner - Mzannar Jewerly</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“The staff at VISIQUE is amazing to work with. They are all very knowledgeable 
                  and SO patient with my kids. There was no pressure to buy and no rushing us in deciding on frames. <br>
                  Thank you!”
                </p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image4.png"
                alt="testimonials-image2"
              /> -->
              <h4>Madus Botha </h4>
              <!-- <h5>Ambassador of Mexico to Lebano</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“I was looking for a good Optometrist in Dubai for a very long time. I finally feel like I’m home.”
                </p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image4.png"
                alt="testimonials-image2"
              /> -->
              <h4>Fawzi Aldibyat </h4>
              <!-- <h5>Ambassador of Mexico to Lebano</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“I used to visit Visique Optometrists in Beirut and I’m glad they are now in Dubai. Excellent friendly customer care and unique variety of eyewear
                  Sync 3 digital lenses amazing!!!”
                </p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
        </div>

        <div class="cards col-md-12 flex-row d-flex justify-content-between">
          <div class="coloumn col-md-4">
            <div class="card-head">
              <!-- <img
                src="../../../assets/images/testimonials-image3.png"
                alt="testimonials-image1"
              /> -->
              <h4>Rene Gopaul 
              </h4>
              <!-- <h5>Owner - Mzannar Jewerly</h5> -->
            </div>
            <div class="card-desp">
              <div class="testimonial-content">
                <p class="text-justify">“Juliana is professional and knowledgeable and offers great insight. It makes me feel at 
                  ease knowing that she knows what she’s talking about and is truly interested in my eye health. She arranged delivery of my trial lenses as I was sick and unable to collect them. She also followed up to check if I had any concerns or questions. 
                  I would highly recommend Visique and Juliana.”</p>
                <!-- <h4>Read More</h4> -->
              </div>
            </div>
          </div>
      </div>
      <h1 class="section-heading text-center">
        <span>And many more satisfied clients!</span>
      </h1>
      
      <!-- <div>
        <p class="text-center manymore">And many more satisfied clients!</p>
      </div> -->
      
  </div>
</div>
