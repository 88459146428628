<div class="contact-lense-wrapper">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Contact Lenses</h1>
      <p>
        Recent years have seen remarkable progress in contact lens fitting, design, and
        materials. VISIQUE is devoted to delivering the most advanced contact lens
        technology from top global manufacturers, incorporating the latest design
        innovations.
      </p>
      <p></p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading">
      <span>Contact lenses</span>
    </h1> -->
    <div class="card-wrapper raw">
      <!-- <div class="image-wrapper">
        <img
          class="card-image"
          src="../../../assets/images/contact-lenses.png"
          alt=""
        />
      </div> -->
      <div class="col-md-8">
        <div class="card-description mb-3">
          VISIQUE is dedicated to offering the latest contact lens technology from top
          international manufacturers, featuring the most up-to-date designs. Our
          specialized optometrists provide exceptional expertise in fitting advanced contact
          lenses.
        </div>
        <h6>
          We offer a wide range of contact lenses, including:
        </h6>
        <div>
          <div>
            <ul>
              <li>Toric lenses for astigmatism</li>
              <li>Multifocal lenses for presbyopia</li>
              <li>Daily disposables</li>
              <li>Cosmetic colored contact lenses</li>
              <li>Lenses for extremely high prescription</li>
              <li>Rigid gas permeable lenses in various designs</li>
              <li>Lenses for post-surgical issues</li>
              <li>Contact lenses for keratoconus</li>
            </ul>
          </div>
        </div>
      </div>
      <img 
        class="card-image col-md-4"
        src="../../../assets/images/contact-lenses.png"
        alt=""
      />
    </div>

    <div class="container-fluid">
      <!-- <h1 class="section-heading">
        <span>Contact lenses</span>
      </h1> -->
      <div class="card-wrapper raw">
        <!-- <div class="image-wrapper">
          <img
            class="card-image"
            src="../../../assets/images/contact-lenses.png"
            alt=""
          />
        </div> -->
        <div >
     
          <h3>Guide to put in contact lenses for the first time:</h3>
            <p>Don’t worry about using contact lenses or trying them for the first time. Here’s
                a simple guide to help you through the process:</p>
            <ol>
                <li>Clean hands are key: Make sure to wash your hands thoroughly with soap and dry them with a
                    towel before handling your lenses. </li>
                <li>Check the lens orientation: Ensure the lens is not inside out by inspecting the edges. If the edges
                    flare outward, the lens is inside out and needs to be flipped. It should
                    have a smooth, rounded shape when correctly positioned. </li>
                <li>Steady your eyelids: Use your right hand to hold your upper eyelid with one finger and pull
                    down your lower eyelid with another. This reduces blinking, but don’t
                    worry if you blink; it’s perfectly normal.</li>
                <li>Gently insert the lens: Place the lens on the tip of your finger and carefully insert it onto your
                    eye while holding your eyelids apart. Blink to allow the lens to settle
                    comfortably into place.</li>
                <p>Practice makes perfect! Whether you’re using colored or regular lenses, the
                    method is the same. With each attempt, it gets easier and more comfortable.</p>
            </ol>
            <h3>Guide to Remove Contact Lenses:</h3>
          
            <ol>
                <li>Start with clean hands:
                    Make sure to thoroughly wash your hands to remove any bacteria that
                    could cause infection, and dry them completely for better hygiene.</li>
                <li>Prepare your eyelids:
                    Using your non-dominant hand, gently pull down your lower eyelid and pull
                    up the upper eyelid by holding your eyelashes toward your eyebrow.</li>
                <li>Pinch the lens:
                    With your thumb and index finger, carefully pinch the sides of the contact
                    lens to remove it. Alternatively, you can move the lens to the corner of your
                    eye, away from the pupil, and then gently pinch it to take it out. </li>
                <li>Move the lens:
                    Look upward and slide the lens to the white part of your eye, making it
                    easier to lift the lens onto your finger. </li>
                <li>Repeat for the other eye by following the same steps to safely remove the second
                    lens. </li>
            </ol>
            <h3>Contact Lenses Irritation:</h3>
            <p>If you&#39;re using contact lenses for the first time, it may feel a bit uncomfortable
                initially, but you’ll quickly adjust. However, if you experience irritation, consider
                the following:</p>
            <ul>
                <li>Maintain lens hygiene – Regularly clean your lenses to prevent irritation
                    and ensure proper eye hygiene. You can also use lubricating eye drops to
                    keep your eyes hydrated, especially if they feel dry. </li>
                <li>Try a hypoallergenic solution – Switching to a hypoallergenic contact lens
                    solution may help reduce discomfort and irritation. </li>
                <li>Avoid touching your eyes – Refrain from rubbing your eyes, as this can
                    aggravate irritation and potentially cause more discomfort. </li>
                <li>Consult a professional – If the irritation persists or worsens, seek advice
                    from a medical professional or optometrist. They can diagnose the cause of
                    the irritation and recommend an appropriate solution.</li>
            </ul>



        </div>
        
      </div>

    
    <!-- <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Lorem ipsum dolor sit amet consectet?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Qui,
                porro possimus quidem voluptatibus accusantium nobis minus
                consequuntur soluta nesciunt, labore modi nulla iusto non libero
                assumenda cum repudiandae at fugiat.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div> -->
  </div>
</div>
