<div class="page-container">
  <div class="banner-wrapper">
    <div class="banner-content">
      <h1>Kids Sunglasses</h1>
      <p>
        Children are at a higher risk of UV damage to their eyes due to the amount of
        time they spend outdoors. That’s why it’s crucial for them to wear sunglasses with
        proper UV protection and impact-resistant lenses. These should be genuine
        sunglasses, not toys, and must clearly indicate the level of UV protection, just like
        adult sunglasses.
      </p>
      <p></p>
    </div>
  </div>
  <div class="container-fluid">
    <!-- <h1 class="section-heading">
      <span>Kids Sunglasses</span>
    </h1> -->
    <div class="card-container">
      <div class="row mb-3">
        <!-- <div class="col-md-6">
          <img
            class="card-image"
            src="../../../assets/images/kids-sunglasses.png"
            alt="logo"
          />
          <h5 class="image-meta">Kids Sunglasses</h5>
        </div>
        <div class="col-md-6 right-content">
          <div class="book-wrapper">
            <button routerLink="/book-an-appointment">
              Book An Appointment
            </button>
          </div>
        </div> -->
        <p>Since children&#39;s eyes are still developing, it is essential to protect them from UV
          radiation with proper sunglasses. Excessive exposure to UV rays can lead to a
          condition known as photokeratitis, often described as sunburn of the eyes,
          causing temporary but painful symptoms such as light sensitivity, redness, and
          excessive tearing. In the long term, this overexposure increases the risk of serious
          eye conditions like cataracts, macular degeneration, and potentially partial or
          total vision loss.</p>
        <h1>Medical Benefits of Getting Sunglasses for Kids</h1>
        <p>
          It’s important to understand that children’s eyes are more vulnerable to UV
          damage. This is due to their larger eyes and the fact that their crystalline lenses
          are clearer than those of adults, allowing more UV light to penetrate and
          potentially harm their vision. Since children spend a lot of time outdoors, this
          exposure becomes even more concerning. However, the harmful effects of UV
          light do not appear immediately and often develop later in life, leading to serious
          conditions such as:
        </p>
        <ul>
          <li>
            <strong>Pterygium:</strong> A growth of tissue on the white part of the eye (conjunctiva) that can
            impair vision if untreated.
          </li>
          <li>
            <strong>Pinguecula:</strong> Yellowish growths that develop on the conjunctiva.
          </li>
          <li>
            <strong>Photokeratitis:</strong>Causes symptoms like redness, light sensitivity, tearing, and
            swelling due to damage to the cornea’s surface.
          </li>
          <li>
            <strong>Cataracts:</strong> Clouding of the lens due to protein clumping, resulting in vision
            impairment.
          </li>
        </ul>
        <p>
          Teaching children healthy eye care habits, like wearing sunglasses, is essential, as
          these practices will protect them now and well into adulthood.
        </p>
        <div class="logo-wrapper">
          <div class="row">
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/lindberg-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/lafont-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/humphrey's-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/barbie-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/cars-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/hello-kitty-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/oio-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/hot-wheels-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/action-man-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/titeuf-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/fisher-price-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/ben10-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/ray-ban-logo.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/life-italia.png"
                alt="logo"
              />
            </div>
            <div class="col-md-3">
              <img
                class="m-2"
                src="../../../assets/images/oakley.png"
                alt="logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-5 mb-5">
      <div class="faq-wrapper">
        <div class="logo-header">
          <img src="../../../assets/images/faq-frame-image.png" alt="" />
          <h2>Frequently Asked Questions</h2>
        </div>
        <div class="specialities-faq">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Is wearing sunglasses for kids only necessary when it is sunny?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                It’s important for children to wear sunglasses outdoors, even on cloudy days, as
                harmful UV rays can still penetrate through the clouds. To protect their eyes,
                always ensure your child has a pair of sunglasses with them, regardless of the
                weather.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Can toy sunglasses also protect the kids’ eyes against radiation as well?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Not all sunglasses provide UV protection, so it&#39;s essential to choose ones that
                guarantee 100% UV blocking to ensure your eyes are fully protected. Always look
                for sunglasses that offer this level of defense against harmful radiation.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What do I do if my child already wears regular eyeglasses?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Some children&#39;s sunglasses come with convenient clip-on options. Additionally,
                photochromic lenses are a great choice as they remain clear indoors and darken
                in sunlight. These sunglasses provide UV protection while ensuring clear vision for
                kids in all lighting conditions.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Does a darker pair of sunglasses mean more protection against UV?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                The tint or darkness of sunglasses does not determine UV protection. In fact, if
                darker lenses lack proper UV protection, they can pose a greater risk to children
                by causing the pupils to dilate, allowing more harmful UV rays to enter the eyes.
              </mat-panel-description>
            </mat-expansion-panel>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Is there a specific style of sunglasses that offers my child’s most protection?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-panel-description>
                Close-fitting sunglasses provide superior UV protection by shielding more light
                from reaching the eyes. Visit our website to discover a variety of children&#39;s
                sunglasses that match your child’s style. You can also explore designs with straps
                to ensure they stay secure during their activities.
              </mat-panel-description>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>
  </div>
</div>
