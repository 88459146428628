<div class="insta-wrapper">
  <div class="container">
    <div class="card-container">
      <h1 class="section-heading">
        <span>Follow us on Instagram</span>
      </h1>

      <!-- Instagram Handle Display -->
      <!-- <div class="instagram-account">
        <a href="https://www.instagram.com/visiqueme/" target="_blank">
          <img src="../../../assets/images/visique_insta.jpg" alt="@visiqueme Instagram">
          <p>Stay Connected on Instagram <span class="instagram-handle">@visiqueme</span></p>
        </a>
      </div> -->

      <div class="instagram-account">
        <a href="https://www.instagram.com/visiqueme/" target="_blank">
          <div class="images">
            <!-- <img src="../../../assets/images/visique_insta.jpg" alt="Visique Instagram Image 1"> -->
            <img src="../../../assets/images/Client 2.jpg" alt="Visique Instagram Image 2">
            <img src="../../../assets/images/Client.jpg" alt="Visique Instagram Image 3">
            <img src="../../../assets/images/Client 1.jpg" alt="Visique Instagram Image 4">
          </div>
          <p>Stay Connected on Instagram <span class="instagram-handle">@visiqueme</span></p>
        </a>
      </div>
      

      <!-- Instagram Images Carousel -->
      <div class="div">
        <owl-carousel-o [options]="customOptions2" *ngIf="instaImages">
          <ng-template carouselSlide *ngFor="let image of instaImages">
            <div class="gallery-box">
              <img *ngIf="image.media_type == 'IMAGE' || image.media_type == 'CAROUSEL_ALBUM'" src="{{ image.media_url }}"
                class="img-wrapper"
                alt="Gallery"
              />
              <video *ngIf="image.media_type == 'VIDEO'" width="350" [muted]="'muted'" autoplay loop>
                <source src="{{image.media_url}}" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
